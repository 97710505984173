import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MarkdownWrapper = (props: any) => {
  return (
    <Markdown
      className="text-base font-normal"
      remarkPlugins={[remarkGfm]}
      components={{
        h2: ({ node, ...props }) => (
          <h2 {...props} className="brand-heading text-xl mb-2 font-medium" />
        ),
        h3: ({ node, ...props }) => (
          <h3 {...props} className="brand-heading text-lg mb-2" />
        ),
        p: ({ node, ...props }) => <p {...props} className="mb-2" />,
        ul: ({ node, ...props }) => <ul {...props} className="mb-2" />,
        a: ({ node, ...props }) => <a {...props} className="underline" />,
      }}
    >
      {props.children}
    </Markdown>
  );
}

export default MarkdownWrapper;
